import { createTheme, lighten } from '@mui/material/styles';
import deepmerge from 'deepmerge';
import commonThemeParams from './themeCommonPaminga';

const themeDarkParameters = {
  nrCodeTheme: 'okaidia',
  palette: {
    mode: 'dark',
    primary: {
      main: commonThemeParams.palette.brand.paminga.lightBlue,
      light: commonThemeParams.palette.darkBlue[400],
      dark: commonThemeParams.palette.darkBlue[600],
      contrastText: '#ffffff',
    },
    secondary: {
      main: commonThemeParams.palette.brand.paminga.lightBlue,
      light: '#2ca5f8',
      dark: '#0166ab',
    },
    background: {
      default: commonThemeParams.palette.darkBlue[700],
      paper: commonThemeParams.palette.darkBlue[600],
      offset: commonThemeParams.palette.darkBlue[800],
      leftNav: commonThemeParams.palette.darkBlue[700],
    },
    segments: {
      innerAnd: commonThemeParams.palette.darkBlue[600],
      middleOr: commonThemeParams.palette.darkBlue[700],
      outerAnd: commonThemeParams.palette.darkBlue[800],
    },
    borderColor: 'rgba(255, 255, 255, 0.13)',
    action: {
      active: commonThemeParams.palette.brand.skyBlue,
      hover: commonThemeParams.palette.brand.skyBlue,
    },
    emailMetrics: {
      qualified: (theme) => theme.palette.text.primary,
      qualifiedBackground: (theme) => theme.palette.grey[200],
      sent: (theme) => theme.palette.text.primary,
      sentBackground: (theme) => theme.palette.grey[200],
      opens: '#328215',
      opensBackground: '#1b420b',
      clicks: '#61b201',
      clicksBackground: '#376900',
      visits: '#30a6f8',
      visitsBackground: '#12669f',
      bounces: '#ff9c00',
      bouncesBackground: '#b06b00',
      unsubscribes: '#e00008',
      unsubscribesBackground: '#671719',
      spamReports: '#e00008',
      spamReportsBackground: '#671719',
    },
    error: {
      main: '#f34f53',
      badge: '#ff3b32',
      contrastText: '#fff',
    },
    fileManager: {
      file: '#696979',
      pdf: '#fcb18d',
      video: '#87ceff',
      videoFocused: '#000000',
      folderZip: '#92d6ad',
      code: '#f2f2fa',
      audio: '#f7b0d0',
      folder: '#696979',
    },
    buttons: {
      primary: {
        color: '#ffffff',
        hoverColor: '#ffffff',
        backgroundColor: '#1f406e',
        backgroundHoverColor: '#2f60a4',
      },
      secondary: {
        color: 'text.primary',
        hoverColor: 'text.primary',
        backgroundColor: '#374965',
        backgroundHoverColor: '#455e85',
      },
      inactive: '#444',
    },
  },
  navigation: {
    appBadge: 'https://res.cloudinary.com/net-results/image/upload/v1707174930/Paminga%20WWW/paminga-badge.svg',
    logoText: 'https://res.cloudinary.com/net-results/image/upload/v1710192862/Paminga%20WWW/logoTextDark.svg',
    iconColor: commonThemeParams.palette.brand.skyBlue,
  },
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: commonThemeParams.palette.darkBlue[600],
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          borderTop: '1px solid rgb(81 81 81)',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: 'text.primary',
          '&:hover': {
            backgroundColor: commonThemeParams.palette.darkBlue[600],
          },
        },
        ellipsis: {
          color: 'rgba(255, 255, 255, 0.3)',
          '&:hover': {
            backgroundColor: 'inherit',
          },
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        ul: {
          '& .Mui-selected': {
            color: 'text.primary !important',
            backgroundColor: `${commonThemeParams.palette.darkBlue[500]} !important`,
          },
          '& .Mui-selected:hover': {
            backgroundColor: `${commonThemeParams.palette.darkBlue[500]} !important`,
          },
          '& .Mui-disabled': {
            color: 'rgba(255, 255, 255, 0.3)',
            backgroundColor: 'inherit',
          },
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        loadingIndicator: {
          color: commonThemeParams.palette.brand.pink,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          // backgroundColor: '#080826',
          // backgroundColor: '#061121',
          backgroundColor: '#091425',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          backgroundColor: commonThemeParams.palette.darkBlue[600],
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          backgroundColor: commonThemeParams.palette.darkBlue[700],
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        content: {
          ':hover': {
            backgroundColor: commonThemeParams.palette.brand.pink,
          },
          '&.Mui-focused': {
            backgroundColor: commonThemeParams.palette.brand.pink,
          },
          '&.Mui-selected': {
            backgroundColor: commonThemeParams.palette.brand.indigo,
            color: '#fff',
            ':hover': {
              backgroundColor: commonThemeParams.palette.brand.indigo,
            },
          },
          '&.Mui-selected.Mui-focused': {
            backgroundColor: commonThemeParams.palette.brand.indigo,
            color: '#fff',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        groupLabel: {
          fontSize: '14px',
          fontWeight: 500,
          color: '#fff',
          borderBottom: '2px solid',
          borderColor: commonThemeParams.palette.darkBlue[800],
          letterSpacing: '.5px',
        },
      },
    },
  },
};

themeDarkParameters.palette.tables = {
  actionIcons: {
    color: themeDarkParameters.palette.primary.contrastText,
    actionIconBackGroundColor: commonThemeParams.palette.brand.techBlue,
    actionIconBackGroundHoverColor: lighten(commonThemeParams.palette.brand.techBlue, 0.3),
  },
  disabledActionIcons: {
    color: '#9e9e9e',
    actionIconBackGroundColor: '#424242',
    actionIconBackGroundHoverColor: '#424242',
  },
};

export default createTheme(deepmerge(commonThemeParams, themeDarkParameters));
