import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import NrButton from '@/components/NrMaterialOverrides/NrButton';
import BaseErrorFallback from '../BaseErrorFallback';

const LoadingErrorFallback = (props) => {
  return (
    <Stack
      p={4}
      pt={2}
      mx="auto"
      direction="column"
      spacing={4}
      alignItems="center"
      justifyContent="flex-start"
      sx={{
        display: props.displayMessageToUser ? 'block' : 'none',
        textAlign: 'center',
        height: '100%',
        width: '80%',
        borderRadius: 6,
        background: (theme) => theme.splashBackground,
      }}
    >
      <BaseErrorFallback {...props}>
        <Box sx={{ ...props.sx }}>
          <Typography variant="h2">We are having trouble loading your data.</Typography>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            We have been notified about the problem, and will figure out what&apos;s up.
          </Typography>
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            If the problem persists please email{' '}
            <a href="mailto:support@net-results.com" target="_blank" rel="noreferrer">
              support@net-results.com
            </a>
            .
          </Typography>
          <NrButton
            onClick={() => {
              // can reload force a hard refresh?
              window.location.reload();
            }}
          >
            Try Again
          </NrButton>
        </Box>
      </BaseErrorFallback>
      <img
        src="https://res.cloudinary.com/net-results/image/upload/v1657403447/SPA/undraw_personal_data.svg"
        alt="We had trouble loading your data."
        style={{ maxWidth: '40%', width: 600 }}
      />
    </Stack>
  );
};

LoadingErrorFallback.defaultProps = {
  error: null,
  displaySnackbar: false,
  snackbarMessage: 'There was a problem completing your request',
  sx: {},
  displayMessageToUser: true,
};

LoadingErrorFallback.propTypes = {
  error: PropTypes.object,
  displaySnackbar: PropTypes.bool,
  snackbarMessage: PropTypes.string,
  sx: PropTypes.object,
  displayMessageToUser: PropTypes.bool,
};

export default LoadingErrorFallback;
