import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Fade, Stack } from '@mui/material';

const NoResultsFound = ({ message, actions, fillEntireContainer, hideImage, sx }) => {
  const [image, setImage] = useState(null);

  useEffect(() => {
    const images = [
      'https://res.cloudinary.com/net-results/image/upload/v1646869930/SPA/undraw_summer.png',
      'https://res.cloudinary.com/net-results/image/upload/v1646857554/SPA/undraw_blank_canvas.png',
      'https://res.cloudinary.com/net-results/image/upload/v1646854158/SPA/undraw_not_found.png',
      'https://res.cloudinary.com/net-results/image/upload/v1646870046/SPA/undraw_empty.png',
    ];
    const getRandomImage = (arr) => arr[Math.floor(Math.random() * arr.length)];
    setImage(getRandomImage(images));
  }, []);

  return (
    <Fade in appear timeout={450}>
      <Stack
        direction="column"
        spacing={2}
        alignItems="center"
        useFlexGap
        p={4}
        sx={{
          mx: 'auto',
          textAlign: 'center',
          color: '#fff',
          width: fillEntireContainer ? '100%' : '50%',
          height: fillEntireContainer ? '100%' : null,
          borderRadius: fillEntireContainer ? null : 4,
          background: (theme) => theme.splashBackground,
          ...sx,
        }}
      >
        {message}
        {hideImage ? null : <img src={image} width={280} alt={message} />}
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          {actions}
        </Stack>
      </Stack>
    </Fade>
  );
};

NoResultsFound.propTypes = {
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  actions: PropTypes.node,
  fillEntireContainer: PropTypes.bool,
  hideImage: PropTypes.bool,
  sx: PropTypes.object,
};

NoResultsFound.defaultProps = {
  message: 'Oh no! We were unable to find any matching items.',
  actions: null,
  fillEntireContainer: false,
  hideImage: false,
  sx: null,
};

export default NoResultsFound;
