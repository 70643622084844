import React from 'react';
import { Box, Fade, Stack, Typography } from '@mui/material';

const GenericErrorFallback = () => {
  return (
    <Box p={2}>
      <Fade in appear timeout={350}>
        <Stack
          direction="column"
          spacing={4}
          alignItems="center"
          p={4}
          sx={{
            mx: 'auto',
            textAlign: 'center',
            width: '50%',
            borderRadius: 4,
            background: (theme) => theme.splashBackground,
          }}
        >
          <Box sx={{ color: '#ffffff' }}>
            <Typography variant="h2">It&apos;s hard work, programming.</Typography>
            <Typography variant="subtitle1">
              And you&apos;ve just got us with something that beat us. We have been notified automagically and will
              figure out what&apos;s going on.
            </Typography>
            <Typography variant="subtitle1">
              Please contact{' '}
              <a href="mailto:support@net-results.com" target="_blank" rel="noreferrer">
                support@net-results.com
              </a>{' '}
              if you continue to see this page fail like this.
            </Typography>
          </Box>
          <img
            src="https://res.cloudinary.com/net-results/image/upload/v1649775727/SPA/undraw_fixing_bugs.svg"
            width={280}
            alt="Something has gone wrong."
          />
        </Stack>
      </Fade>
    </Box>
  );
};

export default GenericErrorFallback;
