import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Box, Collapse, Divider, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { HighlightOff } from '@mui/icons-material';
import { MarkNotificationsAsReadMutation } from 'goldilocks/graphql/notifications/queries.graphql';
// @ts-ignore
import pluralize from 'pluralize';
// @ts-ignore
import { Link } from 'react-router-dom';

type Notification = {
  id: string;
  type:
    | 'FORM_SUBMISSION'
    | 'LEAD_SCORING_THRESHOLD_ACHIEVED'
    | 'REPORT_READY'
    | 'LEAD_STAGE_CHANGED'
    | 'BLOG_POST'
    | 'PLATFORM_UPDATE'
    | 'LISTS_COMBINED'
    | 'VIEW_EXECUTION_READY'
    | 'CONTACTS_EXPORTED'
    | 'ADD_CONTACTS_TO_LIST_BY_SEGMENT'
    | 'FORM_SUBMISSIONS_EXPORTED';
  location: 'NOTIFICATION_CENTER' | 'TOP_BANNER';
  is_global: boolean;
  json: string;
  expires_at: string | null;
  created_at: string;
};

type PlatformNotificationContent = {
  feature_link_url?: string;
  feature_link_text?: string;
  message: string;
  open_in_another_tab?: boolean;
};

type ReportReadyContent = {
  report_id: string;
  report_name: string;
  download_url: string;
};

type ViewExecutionReadyContent = {
  view_id: string;
  view_name: string;
  dashboard_type: string;
};

type ListsCombinedContent = {
  list_id: number;
  list_name: string;
  combined_lists_count: number;
};

type FileExported = {
  file_name: string;
  download_url: string;
};
type ContactsExportedContent = FileExported;
type FormSubmissionsExportedContent = FileExported;

type AddContactsToListBySegmentContent = {
  list_id: number;
  list_name: string;
  is_subscription_list: boolean;
};

const BannerNotifications = ({ notifications }: { notifications: Notification[] | [] }) => {
  const [expandedNotifications, setExpandedNotifications] = useState<Notification[] | []>(notifications);
  const [dismissNotifications] = useMutation(MarkNotificationsAsReadMutation);

  useEffect(() => {
    setExpandedNotifications(notifications);
  }, [notifications]);

  const onDismissNotification = (notificationId: any) => {
    // @ts-ignore
    dismissNotifications({ variables: { notification_ids: [Number(notificationId)] } });
  };

  const collapseNotification = (notificationId: string) => {
    const notificationIndex = expandedNotifications.map((notification) => notification.id).indexOf(notificationId);

    const remainingExpandedNotifications = [...expandedNotifications];

    remainingExpandedNotifications.splice(notificationIndex, 1);

    setExpandedNotifications(remainingExpandedNotifications);
  };

  if (notifications.length === 0) {
    return null;
  }

  const notificationsToRender = notifications.map((notification: Notification) => {
    let dashboardName;
    let dashboardUrl;
    const notificationExpanded = !!expandedNotifications.find(
      (thisNotification) => thisNotification.id === notification.id
    );

    switch (notification.type) {
      case 'PLATFORM_UPDATE': {
        const notificationContent: PlatformNotificationContent = JSON.parse(notification.json);
        return (
          <Collapse
            in={notificationExpanded}
            appear
            onExited={() => {
              onDismissNotification(notification.id);
            }}
            timeout={550}
            key={notification.id}
          >
            <Grid container p={2} key={notification.id}>
              <Grid
                item
                px={1}
                xs={8}
                sm={8}
                md={8}
                lg={8}
                xl={6}
                mx="auto"
                sx={{ textAlign: 'center', position: 'relative' }}
              >
                <Typography>
                  {notificationContent.message}{' '}
                  {notificationContent?.feature_link_url ? (
                    <Link
                      to={{ pathname: notificationContent.feature_link_url }}
                      style={{ color: '#5ebcff' }}
                      onClick={() => {
                        collapseNotification(notification.id);
                      }}
                      target={`${notificationContent.open_in_another_tab ? '_blank' : '_self'}`}
                    >
                      {notificationContent.feature_link_text}
                    </Link>
                  ) : null}
                </Typography>
                <Box sx={{ position: 'absolute', top: -8, right: -32 }}>
                  <Tooltip title="Dismiss this Notification" arrow placement="right">
                    <IconButton
                      aria-label="Dismiss this Notification"
                      onClick={() => {
                        collapseNotification(notification.id);
                      }}
                    >
                      <HighlightOff />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </Collapse>
        );
      }
      case 'REPORT_READY': {
        const notificationContent: ReportReadyContent = JSON.parse(notification.json);
        return (
          <Box key={notification.id}>
            <Typography p={0}>`Your Report named “${notificationContent.report_name}” is ready!</Typography>
            <Link
              to={notificationContent.download_url}
              style={{ color: '#5ebcff' }}
              onClick={() => {
                collapseNotification(notification.id);
              }}
            >
              View it!
            </Link>
          </Box>
        );
      }
      case 'VIEW_EXECUTION_READY': {
        const notificationContent: ViewExecutionReadyContent = JSON.parse(notification.json);
        switch (notificationContent.dashboard_type) {
          case 'FORM_SUBMISSIONS':
            dashboardName = 'Form Submissions Dashboard';
            dashboardUrl = `/reporting/dashboards/form-submissions?viewId=${notificationContent.view_id}`;
            break;
          case 'CONTACTS_TABLE':
            dashboardName = 'Contacts Table';
            dashboardUrl = `/contacts?viewId=${notificationContent.view_id}`;
            break;
          case 'DOWNLOADS':
            dashboardName = 'Downloads Dashboard';
            dashboardUrl = `/reporting/dashboards/new-downloads?viewId=${notificationContent.view_id}`;
            break;
          // no default
        }
        return (
          <Collapse
            in={notificationExpanded}
            appear
            onExited={() => {
              onDismissNotification(notification.id);
            }}
            timeout={550}
            key={notification.id}
          >
            <Grid container p={2} key={notification.id}>
              <Grid
                item
                px={1}
                xs={8}
                sm={8}
                md={8}
                lg={8}
                xl={6}
                mx="auto"
                sx={{ textAlign: 'center', position: 'relative' }}
              >
                <Typography>
                  Your View named “{notificationContent.view_name}” for the {dashboardName} is ready!{' '}
                  <Link
                    to={dashboardUrl}
                    style={{ color: '#5ebcff' }}
                    onClick={() => {
                      collapseNotification(notification.id);
                    }}
                  >
                    View it!
                  </Link>
                </Typography>
                <Box sx={{ position: 'absolute', top: -8, right: -32 }}>
                  <Tooltip title="Dismiss this Notification" arrow placement="right">
                    <IconButton
                      aria-label="Dismiss this Notification"
                      onClick={() => {
                        collapseNotification(notification.id);
                      }}
                    >
                      <HighlightOff />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </Collapse>
        );
      }
      case 'LISTS_COMBINED': {
        const notificationContent: ListsCombinedContent = JSON.parse(notification.json);
        return (
          <Collapse
            in={notificationExpanded}
            appear
            onExited={() => {
              onDismissNotification(notification.id);
            }}
            timeout={550}
            key={notification.id}
          >
            <Grid container p={2} key={notification.id}>
              <Grid
                item
                px={1}
                xs={8}
                sm={8}
                md={8}
                lg={8}
                xl={6}
                mx="auto"
                sx={{ textAlign: 'center', position: 'relative' }}
              >
                <Typography>
                  {notificationContent.combined_lists_count}{' '}
                  {pluralize('List', notificationContent.combined_lists_count)}{' '}
                  {pluralize('was', notificationContent.combined_lists_count)} combined into the List named “
                  {notificationContent.list_name}“{' '}
                  <Link
                    to={`/marketing-list/members?listId=${notificationContent.list_id}`}
                    style={{ color: '#5ebcff' }}
                    onClick={() => {
                      collapseNotification(notification.id);
                    }}
                  >
                    View it!
                  </Link>
                </Typography>
                <Box sx={{ position: 'absolute', top: -8, right: -32 }}>
                  <Tooltip title="Dismiss this Notification" arrow placement="right">
                    <IconButton
                      aria-label="Dismiss this Notification"
                      onClick={() => {
                        collapseNotification(notification.id);
                      }}
                    >
                      <HighlightOff />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </Collapse>
        );
      }
      case 'CONTACTS_EXPORTED': {
        const notificationContent: ContactsExportedContent = JSON.parse(notification.json);
        return (
          <Collapse
            in={notificationExpanded}
            appear
            onExited={() => {
              onDismissNotification(notification.id);
            }}
            timeout={550}
            key={notification.id}
          >
            <Grid container p={2} key={notification.id}>
              <Grid
                item
                px={1}
                xs={8}
                sm={8}
                md={8}
                lg={8}
                xl={6}
                mx="auto"
                sx={{ textAlign: 'center', position: 'relative' }}
              >
                <Typography>
                  Your Contact Export named {notificationContent.file_name} was completed.{' '}
                  <a href={notificationContent.download_url} target="_blank" rel="noreferrer">
                    Download it!
                  </a>
                </Typography>
                <Box sx={{ position: 'absolute', top: -8, right: -32 }}>
                  <Tooltip title="Dismiss this Notification" arrow placement="right">
                    <IconButton
                      aria-label="Dismiss this Notification"
                      onClick={() => {
                        collapseNotification(notification.id);
                      }}
                    >
                      <HighlightOff />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </Collapse>
        );
      }
      case 'FORM_SUBMISSIONS_EXPORTED': {
        const notificationContent: FormSubmissionsExportedContent = JSON.parse(notification.json);
        return (
          <Collapse
            in={notificationExpanded}
            appear
            onExited={() => {
              onDismissNotification(notification.id);
            }}
            timeout={550}
            key={notification.id}
          >
            <Grid container p={2} key={notification.id}>
              <Grid
                item
                px={1}
                xs={8}
                sm={8}
                md={8}
                lg={8}
                xl={6}
                mx="auto"
                sx={{ textAlign: 'center', position: 'relative' }}
              >
                <Typography>
                  Your Form Submissions Export named &#34;{notificationContent.file_name}&#34; was completed. {/* <a */}
                  <a href={notificationContent.download_url} target="_blank" rel="noreferrer">
                    Download it!
                  </a>
                </Typography>
                <Box sx={{ position: 'absolute', top: -8, right: -32 }}>
                  <Tooltip title="Dismiss this Notification" arrow placement="right">
                    <IconButton
                      aria-label="Dismiss this Notification"
                      onClick={() => {
                        collapseNotification(notification.id);
                      }}
                    >
                      <HighlightOff />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </Collapse>
        );
      }
      case 'ADD_CONTACTS_TO_LIST_BY_SEGMENT': {
        const notificationContent: AddContactsToListBySegmentContent = JSON.parse(notification.json);
        return (
          <Collapse
            in={notificationExpanded}
            appear
            onExited={() => {
              onDismissNotification(notification.id);
            }}
            timeout={550}
            key={notification.id}
          >
            <Grid container p={2} key={notification.id}>
              <Grid
                item
                px={1}
                xs={8}
                sm={8}
                md={8}
                lg={8}
                xl={6}
                mx="auto"
                sx={{ textAlign: 'center', position: 'relative' }}
              >
                <Typography>
                  {`The Contacts from your Segment were added to the List "${notificationContent.list_name}".`}{' '}
                  {notificationContent.is_subscription_list ? (
                    <Link
                      to={`/subscription-list/members?listId=${notificationContent.list_id}`}
                      style={{ color: '#5ebcff' }}
                      onClick={() => {
                        collapseNotification(notification.id);
                      }}
                    >
                      View list!
                    </Link>
                  ) : (
                    <Link
                      to={`/marketing-list/members?listId=${notificationContent.list_id}`}
                      style={{ color: '#5ebcff' }}
                      onClick={() => {
                        collapseNotification(notification.id);
                      }}
                    >
                      View list!
                    </Link>
                  )}
                </Typography>
                <Box sx={{ position: 'absolute', top: -8, right: -32 }}>
                  <Tooltip title="Dismiss this Notification" arrow placement="right">
                    <IconButton
                      aria-label="Dismiss this Notification"
                      onClick={() => {
                        collapseNotification(notification.id);
                      }}
                    >
                      <HighlightOff />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </Collapse>
        );
      }
      default:
        break;
    }

    return null;
  });

  return (
    <Collapse in appear timeout={550}>
      <Stack
        direction="column"
        divider={<Divider sx={{ backgroundColor: '#555' }} />}
        sx={{
          color: '#ffffff',
          // @ts-ignore
          backgroundColor: (theme) => theme.palette.brand.darkBlue,
        }}
      >
        {notificationsToRender}
      </Stack>
    </Collapse>
  );
};

export default BannerNotifications;
