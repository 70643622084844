import React, { useState, useEffect, useRef, useContext, forwardRef, createContext, memo } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  OutlinedInput,
  Popper,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList } from 'react-window';

// from here to HookFormAutocomplete is all copy pasted from mui docs
// https://mui.com/material-ui/react-autocomplete/#virtualization
// one difference is that their example uses options like ['thing 1', 'thing 2']
// and we use options like [{label: 'thing 1', value: 1}, {label: 'thing 2', value: 2}]
// so ListboxComponent has a different children prop type
// and renderRow is using the label {dataSet[1].label}
const LISTBOX_PADDING = 8; // px

const renderRow = (props) => {
  const { data, index, style, useLabelsAsFonts } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
    fontFamily: useLabelsAsFonts ? dataSet[1].value : null,
  };

  if (Object.prototype.hasOwnProperty.call(dataSet, 'group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle} key={dataSet[1].value}>
      {dataSet[1].label}
    </Typography>
  );
};

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = (data) => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

// Adapter for react-window
const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
  const { children, useLabelsAsFonts, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (Object.prototype.hasOwnProperty.call(child, 'group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.array.isRequired,
  useLabelsAsFonts: PropTypes.bool.isRequired,
};

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

// this doesn't spread a ...rest prop on purpose.
// if you are missing a mui prop, add it.
// if you want to add a onChange, onBlur, or onFocus, please contact Travis, or be really mindful of performance implications
const HookFormAutocomplete = memo(
  ({
    autoFocus,
    defaultValue,
    disableClearable,
    disabled,
    enableGrouping,
    freeSolo,
    helperText,
    label,
    loading,
    multiple,
    name,
    noOptionsText,
    onChange,
    onInputChange,
    onMouseDown,
    options,
    preventBrowserAutocomplete,
    required,
    sx,
    useLabelsAsFonts,
  }) => {
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
      if (inputRef?.current && autoFocus) {
        inputRef.current.focus();
      }
    }, [inputRef, autoFocus]);

    const isOptionEqualToValue = (option, selectedValue) => {
      return String(option.value) === String(selectedValue?.value);
    };

    // i had to make enableGrouping an extra prop
    // otherwise even when no group passed in options, you would end up with a blank group first in the dropdown
    let groupBy;
    if (enableGrouping) {
      groupBy = (option) => option.group;
    }

    const findCurrentValue = (field) => {
      if (freeSolo) {
        let currentValue;
        if (field.value !== null) {
          const foundOption = options.find((element) => String(element.value) === String(field.value));
          if (!foundOption) {
            currentValue = field.value;
          } else {
            currentValue = foundOption;
          }
        } else {
          currentValue = value;
        }
        return currentValue;
      }

      if (field.value || typeof field.value === 'boolean') {
        return options.find((element) => String(element.value) === String(field.value));
      }
      if (defaultValue) {
        return { label: defaultValue, value: null };
      }
      return value;
    };

    // Rendering this during loading because:
    // if we have a defaultValue but have to wait for options to load, we get a "changing uncontrolled to controlled" warning when the values are loaded.
    if (loading) {
      return (
        <FormControl variant="outlined" size="small" fullWidth>
          <InputLabel>{label}</InputLabel>
          <OutlinedInput
            label={label}
            endAdornment={<CircularProgress size={20} />}
            disabled
            sx={{ backgroundColor: 'divider', ...sx }}
          />
          {helperText ? <FormHelperText variant="filled">{helperText}</FormHelperText> : null}
        </FormControl>
      );
    }

    const preventBrowserAutocompletion = preventBrowserAutocomplete ? { autoComplete: 'new-password' } : null;

    return (
      <Controller
        render={({ field, fieldState: { invalid, error }, formState: { isSubmitting } }) => {
          return (
            <Autocomplete
              // these next 4 props let it be a controlled input https://mui.com/material-ui/react-autocomplete/#controlled-states
              value={findCurrentValue(field)}
              onChange={(e, newValue) => {
                setValue(newValue);
                field.onChange(newValue ? newValue.value : null);
                onChange(newValue, findCurrentValue(field) ?? { value: null });
              }}
              inputValue={inputValue}
              onInputChange={(_, newInputValue) => {
                if (freeSolo) {
                  setValue(newInputValue);
                  field.onChange(newInputValue);
                }
                setInputValue(newInputValue);
                onInputChange(_);
              }}
              onBlur={field.onBlur}
              options={options}
              freeSolo={freeSolo}
              fullWidth
              multiple={multiple}
              disabled={disabled || isSubmitting}
              disableClearable={disableClearable}
              noOptionsText={noOptionsText}
              size="small"
              sx={sx}
              PopperComponent={StyledPopper}
              ListboxComponent={ListboxComponent}
              renderOption={(props, option) => [props, option]}
              renderGroup={(params) => params}
              groupBy={groupBy}
              isOptionEqualToValue={isOptionEqualToValue}
              slotProps={{
                popper: {
                  id: 'hookFormAutocompletePopper',
                },
              }}
              renderInput={(params) => (
                <TextField
                  // this doesn't autofocus like textfield for some reason
                  inputRef={inputRef}
                  {...params}
                  error={invalid}
                  helperText={error ? error.message : helperText}
                  label={label}
                  required={required}
                  autoFocus={autoFocus}
                  variant="outlined"
                  onMouseDown={onMouseDown}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  inputProps={{
                    ...params.inputProps,
                    // Setting autocomplete as 'new-password' is a trick to tell the browser not to attempt to autocomplete the input.
                    // https://stackoverflow.com/questions/50347574/how-to-disable-chrome-autocomplete-feature
                    ...preventBrowserAutocompletion,
                    style: {
                      ...params.inputProps.style,
                      fontFamily: useLabelsAsFonts ? findCurrentValue(field)?.value : 'inherit',
                    },
                  }}
                  // in dndkit dragging an input will cause this to flash from input to label. prevent that
                  InputLabelProps={field.value !== null ? { shrink: true } : {}}
                />
              )}
            />
          );
        }}
        name={name}
      />
    );
  }
);

HookFormAutocomplete.propTypes = {
  autoFocus: PropTypes.bool,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  disableClearable: PropTypes.bool,
  enableGrouping: PropTypes.bool,
  freeSolo: PropTypes.bool,
  helperText: PropTypes.string,
  InputLabelProps: PropTypes.object,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  noOptionsText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  onMouseDown: PropTypes.func,
  preventBrowserAutocomplete: PropTypes.bool,
  required: PropTypes.bool,
  sx: PropTypes.object,
  useLabelsAsFonts: PropTypes.bool,
};

HookFormAutocomplete.defaultProps = {
  autoFocus: false,
  defaultValue: null,
  disableClearable: false,
  disabled: false,
  enableGrouping: false,
  freeSolo: false,
  helperText: null,
  InputLabelProps: {},
  loading: false,
  multiple: false,
  noOptionsText: 'No options were found',
  onChange: () => {},
  onInputChange: () => {},
  onMouseDown: () => {},
  preventBrowserAutocomplete: false,
  required: false,
  sx: {},
  useLabelsAsFonts: false,
};

export default HookFormAutocomplete;
